$(".navbar .nav-link").on('click', function(e) {
   
   const navLink = this;
   const hash = navLink.hash;

   if( hash && $(hash).length  ) {
	   	e.preventDefault();

	   	const top = $(hash).offset().top - 106;

	   	$('html, body').animate({
	       scrollTop: top
	    }, 1000, function() {
	    });

	    $('.nav-link').removeClass('active');
	    $(navLink).addClass('active');
	};
});
// store the hash (DON'T put this code inside the $() function, it has to be executed 
// right away before the browser can start scrolling!
var target = window.location.hash;

// delete hash so the page won't scroll to it
history.pushState(null, null, ' ')

// now whenever you are ready do whatever you want
// (in this case I use jQuery to scroll to the tag after the page has loaded)
$(window).on('load', function() {
    if (target) {
        $('html, body').animate({
            scrollTop: $(target).offset().top - 106
        }, 500, function () {});
    }
});