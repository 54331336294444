require('./bootstrap');
require('./modules/readmore');
require('./modules/country-picker');
require('./modules/smooth-scroll');
require('./modules/counter');

$( document ).ready(function() {


    $('#navbarContent').on('hide.bs.collapse', function () {
        $('.hamburger').toggleClass('is-active');
    })
    $('#navbarContent').on('show.bs.collapse', function () {
        $('.hamburger').toggleClass('is-active');
    })

	$.fn.setAllToMaxHeight = function(){
		console.log('set height');
		return this.height(
		  	Math.max.apply(this, $.map(this, function(e) { return $(e).height() }) )
		)
	}

	$(window).on("load resize", function() {
		$('.tile-details').setAllToMaxHeight();
	});
});