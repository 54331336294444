import counterUp from 'counterup2';
require( 'waypoints/lib/noframework.waypoints.js' )

jQuery(document).ready(function($) {

	const counters = $( '.counter' )
	counters.each(function (ignore, counter) {
		new Waypoint( {
		    element: counter,
		    handler: function() { 
		        counterUp( counter ) 
		        this.destroy()
		    },
		    offset: 'bottom-in-view',
		});
	});
});