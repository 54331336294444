$(document).ready(function(){
	var $el, $ps, $up, totalHeight;

	$(".read-more button").click(function() {
	      
	  	totalHeight = 0

		  $el = $(this);
		  $p  = $el.parent();
		  $up = $('.readmore-box');
		  $ps = $up.find("p");
	  
	  	// measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
	  	const heights = $('.readmore-content').map(function () {
				return $(this).height();
			}).get();
	  	
		const maxHeight = Math.max.apply(null, heights);
		// $ps.each(function() {
		// 	totalHeight += $(this).outerHeight();
		// });

		$up
		    .css({
		      	// Set height to prevent instant jumpdown when max height is removed
		      	"height": $up.height(),
		      	"max-height": 9999
		    })
		    .animate({
		      	"height": maxHeight
		    });
	  
	  	// fade out read-more
	  	$p.fadeOut();
	  
	  	// prevent jump-down
	  	return false;
	    
	});
});